import "../../Pages/Home/Home.css";
const Footerr = () => {
  return (
    <div>
      <footer className="footer" id="resources">
        <div className="attribution">
          Created By
          <span className="outer-link"> And </span>. Coded by{" "}
          <span className="outer-link">Bishal Sarkar</span>.
        </div>
      </footer>
    </div>
  );
};

export default Footerr;
